import React from 'react'
import HandleRoutes from './components/functional/HandleRoutes';

const App = () => {
  return (
    <>
    
        <HandleRoutes />

    </>
  )
}

export default App