import React from 'react'
import { Link } from 'react-router-dom'

const HomePage = () => {


    function approximateTimeRemaining(targetDate) {
        // Ensure the targetDate is in the future
        const currentDate = new Date();
        if (targetDate <= currentDate) {
          return "Less than a minute";
        }
      
        // Calculate the time difference in milliseconds
        let timeDifference = targetDate - currentDate;
      
        // Define the time units and their respective milliseconds
        const timeUnits = [
          { unit: "months", ms: 30 * 24 * 60 * 60 * 1000 },
          { unit: "days", ms: 24 * 60 * 60 * 1000 },
          { unit: "hours", ms: 60 * 60 * 1000 },
          { unit: "minutes", ms: 60 * 1000 },
          { unit: "seconds", ms: 1000 },
        ];
      
        let remainingUnits = 2;
        let remainingTime = "";
      
        for (const unit of timeUnits) {
          const unitCount = Math.floor(timeDifference / unit.ms);
          if (unitCount > 0) {
            remainingTime += `${unitCount} ${unit.unit} `;
            timeDifference -= unitCount * unit.ms;
            remainingUnits--;
          }
      
          if (remainingUnits === 0) {
            break;
          }
        }
      
        return remainingTime.trim();
      }
      


  return (
    <div className='bg-slate-50 dark:bg-slate-900 h-screen w-screen flex items-center justify-center'>
    
        <div className="relative overflow-hidden">
            <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10 sm:py-24">
                <div className="text-center">

                <div className="lg:flex hidden justify-center items-center mb-11"><img src="/BankDetail.png" alt="" className='w-40' /> <span className="mx-5 text-gray-500">|</span> <img src="https://s-cdn.cloud.calci.org/sites/calci/org/images/logos/calci/full/calci.png" alt="" className='w-36' /></div>
                <div className="lg:hidden flex justify-center items-center mb-11"><img src="/Calci.png" alt="" className='w-11' /></div>

                <h1 className="text-4xl sm:text-6xl font-bold text-gray-800 dark:text-gray-200">
                    Enhancing Your Experience
                </h1>

                <p className="mt-11 text-gray-600 dark:text-gray-400 leading-loose">
                    This is our annual scheduled maintenance to bring you an even better BankDetail India experience! Hang tight, and we'll be back in no time (estimated time: {approximateTimeRemaining(new Date("2023-10-3"))}). We're cooking up something big for you, and good things take time. In the meantime, you can <Link className='text-gray-700 dark:text-gray-300 hover:underline' to="/contact">reach out</Link> to us or leave your email, and we'll notify you when we're back to unveil the exciting updates!
                </p>

                <div className="mt-7 sm:mt-12 mx-auto max-w-xl relative">
                    <form>
                    <div className="relative z-10 flex space-x-3 p-3 bg-white border rounded-lg shadow-lg shadow-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:shadow-gray-900/[.2]">
                        <div className="flex-[1_0_0%]">
                        <label htmlFor="hs-search-article-1" className="block text-sm text-gray-700 font-medium dark:text-white"><span className="sr-only">Search article</span></label>
                        <input type="email" name="hs-search-article-1" id="hs-search-article-1" className="p-3 block w-full border-transparent rounded-md outline-none text-gray-600 dark:text-gray-400 dark:bg-gray-800 dark:text-gray-400 h-full" placeholder="Email" autoCapitalize='off' autoComplete='off' />
                        </div>
                        <div className="flex-[0_0_auto]">
                            <button className="p-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-orange-400 text-white hover:bg-orange-500 focus:outline-none transition-all text-sm">
                                <span className="material-symbols-outlined">send</span>
                            </button>
                        </div>
                    </div>
                    </form>

                    <div className="hidden md:block absolute top-0 right-0 -translate-y-12 translate-x-20">
                    <svg className="w-16 h-auto text-orange-500" width="121" height="135" viewBox="0 0 121 135" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164" stroke="currentColor" strokeWidth="10" strokeLinecap="round"/>
                        <path d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5" stroke="currentColor" strokeWidth="10" strokeLinecap="round"/>
                        <path d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874" stroke="currentColor" strokeWidth="10" strokeLinecap="round"/>
                    </svg>
                    </div>

                    <div className="hidden md:block absolute bottom-0 left-0 translate-y-10 -translate-x-32">
                    <svg className="w-40 h-auto text-cyan-500" width="347" height="188" viewBox="0 0 347 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 82.4591C54.7956 92.8751 30.9771 162.782 68.2065 181.385C112.642 203.59 127.943 78.57 122.161 25.5053C120.504 2.2376 93.4028 -8.11128 89.7468 25.5053C85.8633 61.2125 130.186 199.678 180.982 146.248L214.898 107.02C224.322 95.4118 242.9 79.2851 258.6 107.02C274.299 134.754 299.315 125.589 309.861 117.539L343 93.4426" stroke="currentColor" strokeWidth="7" strokeLinecap="round"/>
                    </svg>
                    </div>
                </div>

                </div>
            </div>
        </div>
    
    </div>
  )
}

export default HomePage