import React from 'react'
import { Link } from 'react-router-dom'

const FourOhFOur = () => {
  return (
    <>

        <section className="bg-white dark:bg-gray-900 ">
            <div className="container flex items-center min-h-screen px-6 py-12 mx-auto">
                <div className="flex flex-col items-center max-w-sm mx-auto text-center">
                    <p className="w-11 h-11 flex items-center justify-center text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
                        <span class="material-symbols-outlined text-orange-400">dangerous</span>
                    </p>
                    <h1 className="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">Page not found</h1>
                    <p className="mt-4 text-gray-500 dark:text-gray-400 leading-loose">
                    While the page you're looking for may not be available right now, we're undergoing our annual maintenance break and making improvements to our site. It might be available once the maintenance ends. More information <Link className='text-gray-700 dark:text-gray-300 hover:underline' to="/">here</Link>.
                    </p>

                    <div className="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
                        <button className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 rtl:rotate-180">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                            </svg>


                            <span onClick={() => {window.history.back()}}>Go back</span>
                        </button>

                        <Link className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600" to="/">
                            Take me home
                        </Link>
                    </div>
                </div>
            </div>
        </section>

    </>
  )
}

export default FourOhFOur