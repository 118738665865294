import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import FourOhFOur from '../../Pages/FourOhFOur';
import HomePage from '../../Pages/HomePage';
import ContactPage from '../../Pages/ContactPage';

const HandleRoutes = () => {
  return (
    <>
    
        <Router>


            <Routes>

              <Route path='/' element={<HomePage />} />
              <Route path='/contact' element={<ContactPage />} />

              <Route path='*' element={<FourOhFOur />} />

            </Routes>


        </Router>

    </>
  )
}

export default HandleRoutes